import React from "react";
import Layout from "../components/Layout/Layout";
import "../scss/index.scss";
import WaverChickenGrid from "../components/Grid/WaverChickenGrid";

const IndexPage = () => {
  return (
    <Layout
      metaTitle="Frozen Breaded Chicken for Your Family"
      metaDescription=""
      searchTitle="Home"
    >
      <WaverChickenGrid>
        <div className="indexPage">
          <p>
            Taste our mouthwatering chicken products that have delighted American families since 1937, and you'll find out why we're a "true-blue" family favorite.
          </p>
          <p>
            From the first fresh chicken sold at a simple roadside stand by Victor Weaver to the delicious, time-saving frozen breaded chicken we're famous for today, our goal has always been the same: to help give your family the best in quality, taste, and convenience.
          </p>
          <p>
            It's an achievement we're very proud of -- and a commitment we intend to honor for decades to come.
          </p>
          <p>
            <b>Welcome to Weaver Chicken!</b>
          </p>
        </div>
      </WaverChickenGrid>
    </Layout>
  );
}

export default IndexPage;
